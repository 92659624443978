import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Endpoint, EndpointMode } from "../../models/endpoint";
import React, { useState } from "react";

import { Dialog } from "../Utils/Dialog";
import { Sniffer } from "../../models/utils";
import { capitalize } from "../../utils/strings";

const useStyles = makeStyles(() =>
  createStyles({
    formElement: {
      margin: "16px 0px",
    },
  }),
);

interface Props {
  item: Endpoint;
  modes: string[];
  onConfirm: (item: Endpoint) => void;
  onCancel?: () => void;
  onClose?: () => void;
  sniffers: Sniffer[];
}

function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export const ItemDialog: React.FC<Props> = ({ item, modes, onConfirm, onCancel, onClose, sniffers }) => {
  const [dialogItem, setDialogItem] = useState({ ...clone(item), sniffers: clone(sniffers) });
  const classes = useStyles();

  const changeDefaultUrlForSniffer = (sniffer: Sniffer, wasDefault: boolean) => {
    const newSniffers = clone(dialogItem.sniffers);
    let newDefaultEndpoint: number | null = item.id;
    if (wasDefault) {
      const oldSniffer = sniffers.filter((s) => s.id === sniffer.id);
      if (oldSniffer.length === 0) return;
      const wasDefaultInitially = oldSniffer[0].defaultEndpointId === item.id;
      // Do not override the old configuration.
      newDefaultEndpoint = wasDefaultInitially ? null : oldSniffer[0].defaultEndpointId;
    }
    newSniffers.filter((s) => s.id === sniffer.id).forEach((s) => (s.defaultEndpointId = newDefaultEndpoint));
    setDialogItem((prev) => ({ ...prev, sniffers: newSniffers }));
  };

  const enableCheckbox = (sniffer: Sniffer) => {
    return (
      (sniffer.key === "research/antispoofing" && dialogItem.mode === "release") ||
      (sniffer.key === "self-checkout/age-antispoofing" && dialogItem.mode === "research")
    );
  };

  const isDefault = (sniffer: Sniffer, endpointId: number): boolean => {
    return sniffer.defaultEndpointId === endpointId;
  };

  const changeMode = (mode: EndpointMode) => {
    // Remove default id from research or self-checkout if they are selected.
    const newDialogItem = { ...clone(dialogItem), mode };
    if (mode === "release") {
      const initialResearchSniffer = sniffers.filter((s) => s.key === "research/antispoofing");
      if (initialResearchSniffer.length === 0) return;
      const researchSnifferInitialEndpointId = initialResearchSniffer[0].defaultEndpointId;
      newDialogItem.sniffers
        .filter((s) => s.key === "research/antispoofing")
        // Do not override the `research/antispoofing` default sniffer with null in this case.
        .forEach((s) => (s.defaultEndpointId = researchSnifferInitialEndpointId));
    } else if (mode === "research") {
      const initialSelfCheckoutAntiSpoofingSniffer = sniffers.filter((s) => s.key === "self-checkout/age-antispoofing");
      if (initialSelfCheckoutAntiSpoofingSniffer.length === 0) return;
      const selfCheckoutAntiSpoofingSnifferInitialEndpointId =
        initialSelfCheckoutAntiSpoofingSniffer[0].defaultEndpointId;
      newDialogItem.sniffers
        .filter((s) => s.key === "self-checkout/age-antispoofing")
        // Do not override the `self-checkout/age-antispoofing` default sniffer with null in this case.
        .forEach((s) => (s.defaultEndpointId = selfCheckoutAntiSpoofingSnifferInitialEndpointId));
    }

    setDialogItem(newDialogItem);
  };

  return (
    <Dialog
      title={item.name ? `Endpoint '${item.name}'` : "New endpoint"}
      action={"Save"}
      onConfirm={() => onConfirm(dialogItem)}
      onClose={onClose}
      onCancel={onCancel}
      open={!!item}
      preventAutoClose={true}
      large={true}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            className={classes.formElement}
            label="Name"
            value={dialogItem.name}
            onChange={(e) => setDialogItem({ ...dialogItem, name: e.target.value })}
            fullWidth={true}
          />

          <TextField
            className={classes.formElement}
            label="URL"
            value={dialogItem.url}
            onChange={(e) => setDialogItem({ ...dialogItem, url: e.target.value })}
            fullWidth={true}
          />

          <FormControl fullWidth={true} className={classes.formElement}>
            <InputLabel htmlFor="mode">Mode</InputLabel>
            <Select
              fullWidth={true}
              id="mode"
              label="Mode"
              value={dialogItem.mode}
              onChange={(e) => changeMode(`${e.target.value}` as EndpointMode)}
            >
              {modes.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {capitalize(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Default sniffers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dialogItem.sniffers.map((s) => {
                return (
                  <TableRow key={s.name}>
                    <TableCell component="th" scope="row">
                      {s.name}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={isDefault(s, item.id)}
                        onChange={() => changeDefaultUrlForSniffer(s, isDefault(s, item.id))}
                        color="primary"
                        disabled={enableCheckbox(s)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Dialog>
  );
};
