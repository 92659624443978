import { FilteredType } from "../utils/types";
import { PredictionConfiguration } from "./config";
import { TinyBbox } from "./prediction";
import { VariantType } from "notistack";

// This file contains all the information used in several model files

// IMPORTANT: 'ModelType' interface and 'modelTypeName' method must match with
// the definition in GO (See file 'server/tensorflow/model_type.go').
export enum ModelType {
  DepthFAS = "depth_fas",
  MaskDetector = "mask_detector",
  PatchFAS = "patch_fas",
  AgeEstimation = "age_estimation",
  BiologicalSex = "biological_sex",
  FIQA = "fiqa",
  RGBFAS = "rgb_fas",
  ContextFAS = "context_fas",
  RGBFASV2 = "rgb_fas_v2",
  EffNet = "effnet",
  MetaFAS = "meta_fas",
  MetaFASV2 = "meta_fas_v2",
  IDRnD = "id_rnd",
  MetaFASV2B = "meta_fas_v2_b",
  MetaFASV3 = "meta_fas_v3",
  MetaFASV4 = "meta_fas_v4",
}

export enum ModelTypeName {
  DepthFAS = "Depth FAS",
  MaskDetector = "Mask Detector",
  PatchFAS = "Patch FAS",
  AgeEstimation = "Age Estimation",
  BiologicalSex = "Biological Sex Estimation",
  FIQA = "FIQA",
  RGBFAS = "RGB FAS",
  ContextFAS = "Context FAS",
  RGBFASV2 = "RGB FAS V2",
  EffNet = "EffNet",
  MetaFAS = "Meta FAS",
  MetaFASV2 = "Meta FAS V2",
  IDRnD = "ID R&D",
  MetaFASV2B = "Meta FAS V2 B",
  MetaFASV3 = "Meta FAS V3",
  MetaFASV4 = "Meta FAS V4",
}

export const hasConfidence = (modelType: ModelType) => {
  return (
    [
      ModelType.RGBFAS,
      ModelType.RGBFASV2,
      ModelType.MetaFAS,
      ModelType.MetaFASV2,
      ModelType.MetaFASV2B,
      ModelType.MetaFASV3,
      ModelType.MetaFASV4,
    ].indexOf(modelType) >= 0
  );
};

export type ModelCheckpointMap = { [key in ModelType]: string };

export function getModelTypeName(t: ModelType) {
  return Object.values(ModelTypeName)[Object.values(ModelType).indexOf(t)];
}

export function getModelType(n: ModelTypeName) {
  return Object.values(ModelType)[Object.values(ModelTypeName).indexOf(n)];
}

export const modelThresholdsFields: Array<{
  field: keyof FilteredType<PredictionConfiguration, number>;
  modelType: ModelType;
}> = [
  { field: "depthFASThreshold", modelType: ModelType.DepthFAS },
  { field: "maskDetectorThreshold", modelType: ModelType.MaskDetector },
  { field: "patchFASThreshold", modelType: ModelType.PatchFAS },
  { field: "rgbFASThreshold", modelType: ModelType.RGBFAS },
  { field: "rgbFASV2Threshold", modelType: ModelType.RGBFASV2 },
  { field: "contextFASThreshold", modelType: ModelType.ContextFAS },
  { field: "fiqaThreshold", modelType: ModelType.FIQA },
  { field: "effNetThreshold", modelType: ModelType.EffNet },
  { field: "metaFASThreshold", modelType: ModelType.MetaFAS },
  { field: "metaFASV2Threshold", modelType: ModelType.MetaFASV2 },
  { field: "metaFASV2BThreshold", modelType: ModelType.MetaFASV2B },
  { field: "metaFASV3Threshold", modelType: ModelType.MetaFASV3 },
  { field: "metaFASV4Threshold", modelType: ModelType.MetaFASV4 },
];

export interface CameraInfo {
  camera: MediaDeviceInfo;
  capabilities: MediaTrackCapabilities;
}
export interface TimeStruct {
  key: string;
  startTime: number;
  endTime: number;
}

export interface Feedback {
  variant: VariantType;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "center" | "right";
  duration?: number | null;
  message: string;
}

export interface Sniffer {
  id: number;
  name: string;
  key: string;
  defaultEndpointId: number | null;
}

export interface FCMResult {
  image: string;
  bbox: TinyBbox;
  score: number;
  brightnessInfo?: BrightnessInfo;
  brightnessInfoMask?: BrightnessInfoMask;
}

export interface PredictionModel {
  key: ModelType;
  inFormula: boolean;
  antiSpoofing: boolean;
}

/** Represents the information about the brightness of the image.
 * - score: brightness value.
 * - duration: time spent on calculating the image brightness value.
 */
export interface BrightnessInfo {
  score: number;
  duration: number;
}

/** Represents the information about the brightness of the image (using the new masks method).
 * - score: brightness value.
 * - lowerMask: mask applied to low values of brightness
 * - upperMask: mask applied to high values of brightness
 * - duration: time spent on calculating the image brightness value.
 */
export interface BrightnessInfoMask {
  score: number;
  lowerMask: number;
  upperMask: number;
  duration: number;
  tooDarkThreshold: number;
  tooBrightThreshold: number;
  lowerMaskThreshold: number;
  upperMaskThreshold: number;
  lowerMaskAcceptanceThreshold: number;
  upperMaskAcceptanceThreshold: number;
}
