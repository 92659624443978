import { ConfigurationPanel, EnvSettings, SelfieCaptureType } from "./models/config";
import { Feedback, ModelType, ModelTypeName } from "./models/utils";
import { ImageExtensionFormat, Picture } from "./models/image";

import { LogTableColumn } from "./models/log";
import { Plugin } from "./plugin/plugin";
import React from "react";
import { Session } from "./models/account";

interface ContextProps {
  tinyFaceReady: boolean;
  setTinyFaceReady: React.Dispatch<React.SetStateAction<boolean>>;
  configLoaded: boolean | undefined;
  setConfigLoaded: (loaded: boolean | undefined) => void;
  consent: Consent;
  setConsent: React.Dispatch<React.SetStateAction<Consent>>;
  session?: Session;
  setSession: React.Dispatch<React.SetStateAction<Session | undefined>>;
  panelConfigurationData: ConfigurationPanel;
  setPanelConfigurationData: React.Dispatch<React.SetStateAction<ConfigurationPanel>>;
  setFeedback: (data: Feedback) => string | number | null | undefined;
  downloadingImages: boolean;
  setDownloadingImages: React.Dispatch<React.SetStateAction<boolean>>;
  envSettings: EnvSettings;
  plugins: Plugin<any>[];
}

export interface Consent {
  accept: boolean;
}

export const GlobalContext = React.createContext({
  consent: { accept: false },
} as ContextProps);

export const IndexForPlots: { [key: string]: number } = {};

export const initialPanelConfigurationData: ConfigurationPanel = {
  predictionConfiguration: {
    requestedModels: [
      { key: ModelType.DepthFAS, inFormula: true, antiSpoofing: true },
      { key: ModelType.MaskDetector, inFormula: true, antiSpoofing: true },
      { key: ModelType.RGBFAS, inFormula: true, antiSpoofing: true },
    ],
    predictionResult: [
      {
        model: ModelType.DepthFAS,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.MaskDetector,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.PatchFAS,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.RGBFAS,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.RGBFASV2,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.ContextFAS,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.FIQA,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.BiologicalSex,
        percentage: false,
        text: true,
        antiSpoofing: false,
      },
      {
        model: ModelType.AgeEstimation,
        percentage: false,
        text: true,
        antiSpoofing: false,
      },
      {
        model: ModelType.EffNet,
        percentage: true,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.MetaFAS,
        percentage: false,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.MetaFASV2,
        percentage: false,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.MetaFASV2B,
        percentage: false,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.MetaFASV3,
        percentage: false,
        text: true,
        antiSpoofing: true,
      },
      {
        model: ModelType.MetaFASV4,
        percentage: false,
        text: true,
        antiSpoofing: true,
      },
    ],
    showPrediction: true,
    showFeedback: true,
    minFaceSize: 300,
    depthFASThreshold: 0.5,
    maskDetectorThreshold: 0.5,
    patchFASThreshold: 0.5,
    rgbFASThreshold: 0.5,
    rgbFASV2Threshold: 0.51,
    rgbFASV2ConfidenceThreshold: 0.933,
    contextFASThreshold: 0.5,
    fiqaThreshold: 2.0,
    effNetThreshold: 0.5,
    metaFASThreshold: 0.84,
    metaFASConfidenceThreshold: 0,
    metaFASV2Threshold: 0.87,
    metaFASV2ConfidenceThreshold: 0,
    showStoreImagesOption: true,
    metaFASV2BThreshold: 0.87,
    metaFASV2BConfidenceThreshold: 0,
    metaFASV3Threshold: 0.856,
    metaFASV3ConfidenceThreshold: 0,
    metaFASV4Threshold: 0.856,
    metaFASV4ConfidenceThreshold: 0,
  },
  scanConfiguration: {
    selfieCaptureType: SelfieCaptureType.Scan,
    scanDelayNumber: 2,
    scanDelayTime: 700,
    tinyFaceDetectionSensibility: 0.7,
    tinyFaceDetectorThreshold: 0.85,
    faceCaptureModuleDebug: false,
    faceCaptureModuleShowOverlay: true,
    faceCaptureModuleUseBrightnessMaskMethod: true,
    faceCaptureModuleUseBrightnessMethod: true,
    faceCaptureModuleTooDarkThresholdMask: 40,
    faceCaptureModuleTooBrightThresholdMask: 215,
    faceCaptureModuleLowerMaskThreshold: 10,
    faceCaptureModuleUpperMaskThreshold: 235,
    faceCaptureModuleLowerMaskAcceptanceThreshold: 0.1,
    faceCaptureModuleUpperMaskAcceptanceThreshold: 0.1,
    faceCaptureModuleOverlaySize: "xs",
    faceCaptureModuleOverlaySizeLandscape: "md",
    aiSessionManagerURL: "",
    aiClientModuleDelay: 200,
    faceCaptureModuleInitialAutocaptureDelay: 1000,
    faceCaptureModuleAutocaptureDelay: 200,
    faceCaptureModuleFaceSizeDeviation: 0.05,
    faceCaptureModuleFaceSizeDeviationLandscape: 0.08,
    faceCaptureModuleMinBBoxSize: 200,
    faceCaptureModuleMinBBoxSizeLandscape: 445,
    faceCaptureModuleIdealBBoxRelativeSize: 0.65,
    faceCaptureModuleIdealBBoxRelativeSizeLandscape: 0.6,
    faceCaptureModuleCenterDeviation: 0.6,
    faceCaptureModuleMaxBBoxRelativeSizePortrait: 0.95,
    faceCaptureModuleMaxBBoxRelativeSizeLandscape: 0.9,
    faceCaptureModuleRelativeEdgeCropping: 0,
    faceCaptureModuleScoreThreshold: 0.75,
    faceCaptureModuleTooDarkThreshold: 40,
    faceCaptureModuleTooBrightThreshold: 215,
    aiClientLaplacianEnabled: false,
    startButtonLabel: "start",
    showStartButton: true,
    devButtonLabel: "dev mode",
    showDevButton: true,
    minImageSize: 500,
    maxImageSize: 2000,
    pictureResolutionWidth: 1280,
    pictureResolutionHeight: 720,
    imageExtensionFormat: ImageExtensionFormat.PNG,
    imageJPEGCompression: 0.95,
    selectedInputPicture: Picture.OriginalPicture,
    numberPixelsWidth: 220,
    numberPixelsHeight: 220,
    minPercentageFaceOverImage: 0.35,
    maxPercentageFaceOverImage: 0.8,
    upMarginPercentageCropping: 0.4,
    downMarginPercentageCropping: 0.2,
    leftMarginPercentageCropping: 0.2,
    rightMarginPercentageCropping: 0.2,
    relativeMarginPercentageCropping: 0.3,
    showExpectedLocation: true,
    showExpectedBrightness: true,
    expectedLocationIsMandatory: false,
    expectedBrightnessIsMandatory: false,
  },
  logConfiguration: {
    showDebugImages: true,
    showHistograms: true,
    modelsResultDisplay: [
      ModelType.DepthFAS,
      ModelType.MaskDetector,
      ModelType.PatchFAS,
      ModelType.RGBFAS,
      ModelType.RGBFASV2,
      ModelType.ContextFAS,
    ],
    logTableColumnsOrder: [
      LogTableColumn.ID,
      LogTableColumn.Thumbnail,
      LogTableColumn.Time,
      LogTableColumn.PredictionStatus,
      LogTableColumn.ErrorCode,
      LogTableColumn.PredictionResult,
      LogTableColumn.ExpectedResult,
      LogTableColumn.Feedback,
      LogTableColumn.Source,
      LogTableColumn.UserEmail,
      LogTableColumn.Organisation,
      LogTableColumn.ScanType,
      LogTableColumn.Session,
      LogTableColumn.IsSessionResult,
      LogTableColumn.SessionResult,
      LogTableColumn.SessionScore,
      LogTableColumn.SessionFormula,
      ModelTypeName.MetaFAS,
      ModelTypeName.IDRnD,
      ModelTypeName.AgeEstimation,
      ModelTypeName.EffNet,
      ModelTypeName.RGBFASV2,
      ModelTypeName.BiologicalSex,
      LogTableColumn.ExpectedLocation,
      LogTableColumn.ExpectedBrightness,
      LogTableColumn.OS,
      LogTableColumn.Browser,
      LogTableColumn.Device,
      LogTableColumn.Tag,
      LogTableColumn.AIServiceURL,
      LogTableColumn.Endpoint,
      LogTableColumn.Mode,
      LogTableColumn.StoreImagesEndpoint,
      LogTableColumn.LaplacianScore,
      LogTableColumn.LaplacianDuration,
      LogTableColumn.BrightnessScore,
      LogTableColumn.BrightnessDuration,
    ],
    saveIp: false,
    queryAuditLog: false,
    csvHeaders: [],
    limitImagesDownload: 200,
  },
  consentConfiguration: {
    consent: `Our R&D team will use the information from this demo for testing: to understand why images passed or failed and to assess the impact of different lighting conditions and other factors.
  <br>
  <br>
  We will not use the information to train and develop the technology and we will delete it once it has served its purpose.`,
    consentCheckbox: "I have read and understand the privacy notice.",
    showConsentCheckbox: true,
    hideDataCollectorMainPage: false,
  },
  endpointsConfiguration: {
    age: {
      storeImages: false,
    },
    research: {
      storeImages: false,
    },
    ageAntiSpoofing: {
      storeImages: false,
    },
    antiSpoofing: {
      storeImages: false,
    },
    selfcheckoutAgeAntiSpoofing: {
      storeImages: false,
    },
  },
  pluginsConfiguration: [],
  uploadConfiguration: {
    tinyFaceDetectorThreshold: 0.75,
    maxFaces: 1,
    minFileSize: 50,
    maxFileSize: 3000,
    minImageWidth: 300,
    maxImageWidth: 2000,
    minImageHeight: 300,
    maxImageHeight: 200,
    extensionFormats: [ImageExtensionFormat.JPEG],
    selectedInputPicture: Picture.OriginalPicture,
    relativeMarginPercentageCropping: 0.3,
  },
};
