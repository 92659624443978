import * as faceapi from "face-api.js";

import { ConfigCropping, ResultRescale, checkPicture } from "../../../utils/cropping";
import { ImageExtensionFormat, VideoElement } from "../../../models/image";
import React, { useCallback, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { checkFace, takeSelfie, waitForFace } from "../../../utils/streaming";

import CameraAltRoundedIcon from "@material-ui/icons/CameraAltRounded";
import { CameraInfo } from "../../../models/utils";
import { GlobalContext } from "../../../globalContext";
import IconButton from "@material-ui/core/IconButton";
import RepeatRoundedIcon from "@material-ui/icons/RepeatRounded";
import { SelfieCaptureType } from "../../../models/config";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { TinyBbox } from "../../../models/prediction";
import { TinyFace } from "../../../utils/tinyface";
import Typography from "@material-ui/core/Typography";
import { Webcam } from "./Webcam/Webcam";
import classNames from "classnames";
import { imageSize as imgSize } from "../../Log/LogDetail/LogDetail";
import { useSnackbar } from "notistack";

const feedbackDuration = 4000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0 32px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "120px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "325px",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "50vw",
      },
    },
    parentContainer: {
      width: "100%",
      height: "auto",
      margin: "0 auto",
      paddingTop: "10px",
    },
    facesNumberParent: {
      textAlign: "center",
      minHeight: "75px",
      marginTop: "10px",
    },
    facesNumber: {
      display: "inline",
      color: theme.palette.primary.dark,
    },
    facesNumberText: {
      fontSize: "32px",
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.primary.main,
    },
    takePhotoButtonParent: {
      textAlign: "center",
      marginTop: "20px",
    },
    previewButtonParent: {
      display: "inline-block",
      textAlign: "center",
      marginTop: "20px",
      padding: "0px 20px 0px 20px",
    },
    iconLabel: {
      fontSize: "20px",
    },
    cameraIcon: {
      width: 64,
      height: 64,
    },
    previewButtonsContainer: {
      textAlign: "center",
    },
    loadingContainer: {
      webkitTransform: "scaleX(-1)",
      transform: "scaleX(-1)",
      height: "auto",
      width: "100%",
      borderRadius: "20px",
      marginTop: "20px",
      backgroundColor: theme.palette.primary.light,
    },
    sendMessage: {
      borderLeft: "1px solid",
      borderLeftColor: theme.palette.primary.light,
    },
  }),
);

export function rescaleBBox(video: VideoElement, imageDims: faceapi.Dimensions, originalBBox: faceapi.Box<any>) {
  const cameraW = video.videoWidth;
  const cameraH = video.videoHeight;
  const isInPortrait = cameraW < cameraH;
  const low = isInPortrait ? cameraW : cameraH;
  const high = isInPortrait ? cameraH : cameraW;
  const realWidth = isInPortrait ? low : high;
  const realHeight = isInPortrait ? high : low;
  const widthScaleFactor = realWidth / imageDims.width;
  const heightScaleFactor = realHeight / imageDims.height;

  const resultRescale: ResultRescale = {
    rescaledBBox: {
      x: originalBBox.x * widthScaleFactor,
      y: originalBBox.y * heightScaleFactor,
      width: originalBBox.width * widthScaleFactor,
      height: originalBBox.height * heightScaleFactor,
    },
    pictureWidth: realWidth,
    pictureHeight: realHeight,
  };

  return resultRescale;
}
/**
 * onScanFace is a method used to scan your face and to inform if there is any condition not fullfilled by the user
 * (small face, not center face, etc.). When there is no errors, a picture is taken.
 * @param video
 * @param stream
 * @param scanDelayTime
 * @param scanDelayNumber
 * @param threshold
 * @param setFeedbackSnackbar
 * @param detectionSensibility
 * @param configJson
 * @param feedbackErrors
 * @param faceDetected
 * @param format
 * @param compression
 * @returns Promise<...>
 */
const onScanFace = (
  video: HTMLVideoElement,
  stream: MediaStreamTrack,
  scanDelayTime: number,
  scanDelayNumber: number,
  threshold: number,
  setFeedbackSnackbar: (msg: string, msgType: "info" | "warning", buttonScanType?: boolean) => void,
  detectionSensibility: number,
  configJson: string,
  feedbackErrors: (
    video: HTMLVideoElement,
    imgDImensions: faceapi.Dimensions,
    box: faceapi.Box,
    configCropping: ConfigCropping,
    buttonScanType?: boolean,
  ) => ResultRescale | undefined,
  faceDetected: (
    selfie: string,
    resultRescale: ResultRescale,
    stream: MediaStreamTrack,
    video: HTMLVideoElement,
    score: number,
  ) => void,
  format: ImageExtensionFormat,
  compression: number,
) => {
  let continuousFacesDetected = 0;
  return waitForFace(video, scanDelayTime, threshold, (r) => {
    if (r.length > 1) {
      setFeedbackSnackbar("More than one face detected", "warning");
      setFeedbackSnackbar(`${r.length} faces detected`, "info");
      continuousFacesDetected = 0;
      return undefined;
    } else if (r.length === 0) {
      setFeedbackSnackbar("No face detected", "warning");
      continuousFacesDetected = 0;
      return undefined;
    } else if (r.length === 1 && r[0].score < detectionSensibility) {
      setFeedbackSnackbar("Face detected but Face detector confidence score is too low", "warning");
      continuousFacesDetected = 0;
      return undefined;
    } else if (continuousFacesDetected < scanDelayNumber - 1) {
      setFeedbackSnackbar("Don't move, taking a photo...", "info");
      continuousFacesDetected++;
      return undefined;
    }

    const configParsed = JSON.parse(configJson) as ConfigCropping;

    const resultRescale = feedbackErrors(video, r[0].imageDims, r[0].box, configParsed);

    const result = resultRescale ? { resultRescale: resultRescale, score: r[0].score } : undefined;

    return result;
  }).then((result) => {
    const selfie = takeSelfie(video, format, compression);
    faceDetected(selfie, result.resultRescale, stream, video, result.score);
  });
};

interface Props {
  camera: MediaDeviceInfo;
  onFaceDetected: (
    img: string,
    info: CameraInfo,
    bbox: TinyBbox,
    pictureHeight: number,
    pictureWidth: number,
    score: number,
  ) => Promise<void>;
}

export const SelfieCaptureLoader: React.FC<Props> = (props) => {
  // Button capture
  const [video, setVideo] = useState<HTMLVideoElement>();
  const [stream, setStream] = useState<MediaStreamTrack>();
  const [selfiePreview, setSelfiePreview] = useState<string>("");

  const classes = useStyles();

  const { panelConfigurationData } = React.useContext(GlobalContext);

  //Snack queue
  const { enqueueSnackbar } = useSnackbar();

  const { camera, onFaceDetected } = props;
  const threshold = panelConfigurationData.scanConfiguration.tinyFaceDetectorThreshold;

  // Scan capture
  const detectionSensibility = panelConfigurationData.scanConfiguration.tinyFaceDetectionSensibility;
  const scanDelayNumber = panelConfigurationData.scanConfiguration.scanDelayNumber;
  const scanDelayTime = panelConfigurationData.scanConfiguration.scanDelayTime * 1000;

  // Image resolution and cropping conditions
  const configCropping: ConfigCropping = {
    ...panelConfigurationData.scanConfiguration,
    ...panelConfigurationData.scanConfiguration,
  };

  const configJson = JSON.stringify(configCropping);

  const getImageSize = async (imgSrc: string) => {
    const metadata = await imgSize(imgSrc);
    const pictureSize = metadata.size / 1000;
    return pictureSize;
  };

  const setFeedbackSnackbar = useCallback(
    (msg: string, type: "info" | "warning", buttonScanType?: boolean) => {
      enqueueSnackbar(msg, {
        variant: type,
        autoHideDuration: feedbackDuration,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      if (buttonScanType) {
        setSelfiePreview("");
      }
    },
    [enqueueSnackbar],
  );

  const feedbackErrors = useCallback(
    (
      video: HTMLVideoElement,
      imageDims: faceapi.Dimensions,
      box: faceapi.Box<any>,
      configCropping: ConfigCropping,
      buttonScanType?: boolean,
    ) => {
      const resultRescale = rescaleBBox(video, imageDims, box);
      const result = checkPicture(resultRescale, configCropping);

      if (!result.valid) {
        result.errors.forEach((error) => {
          setFeedbackSnackbar(error, "warning", buttonScanType);
        });
        return undefined;
      } else {
        return resultRescale;
      }
    },
    [setFeedbackSnackbar],
  );

  const faceDetected = useCallback(
    (
      selfie: string,
      resultRescale: ResultRescale,
      stream: MediaStreamTrack,
      video: HTMLVideoElement,
      score: number,
      buttonScanType?: boolean,
    ) => {
      const info: CameraInfo = {
        camera: camera,
        capabilities: stream.getCapabilities(),
      };

      getImageSize(selfie).then((result) => {
        if (result >= configCropping.minImageSize && result <= configCropping.maxImageSize) {
          onFaceDetected(
            selfie,
            info,
            resultRescale.rescaledBBox,
            resultRescale.pictureHeight,
            resultRescale.pictureWidth,
            score,
          );
        } else {
          if (result < configCropping.minImageSize) {
            setFeedbackSnackbar("Image is too small", "warning");
          } else if (result > configCropping.maxImageSize) {
            setFeedbackSnackbar("Image is too big", "warning");
          }
          if (buttonScanType) {
            setSelfiePreview("");
          } else {
            onScanFace(
              video,
              stream,
              scanDelayTime,
              scanDelayNumber,
              threshold,
              setFeedbackSnackbar,
              detectionSensibility,
              configJson,
              feedbackErrors,
              faceDetected,
              panelConfigurationData.scanConfiguration.imageExtensionFormat,
              panelConfigurationData.scanConfiguration.imageJPEGCompression,
            );
          }
        }
      });
    },
    [
      camera,
      onFaceDetected,
      setFeedbackSnackbar,
      configCropping.minImageSize,
      configCropping.maxImageSize,
      detectionSensibility,
      threshold,
      scanDelayTime,
      scanDelayNumber,
      configJson,
      feedbackErrors,
      panelConfigurationData.scanConfiguration.imageExtensionFormat,
      panelConfigurationData.scanConfiguration.imageJPEGCompression,
    ],
  );

  const onCameraReadyScan = useCallback(
    (video: HTMLVideoElement, stream: MediaStreamTrack) => {
      onScanFace(
        video,
        stream,
        scanDelayTime,
        scanDelayNumber,
        threshold,
        setFeedbackSnackbar,
        detectionSensibility,
        configJson,
        feedbackErrors,
        faceDetected,
        panelConfigurationData.scanConfiguration.imageExtensionFormat,
        panelConfigurationData.scanConfiguration.imageJPEGCompression,
      );
    },
    [
      threshold,
      detectionSensibility,
      scanDelayNumber,
      scanDelayTime,
      configJson,
      faceDetected,
      feedbackErrors,
      setFeedbackSnackbar,
      panelConfigurationData.scanConfiguration.imageExtensionFormat,
      panelConfigurationData.scanConfiguration.imageJPEGCompression,
    ],
  );

  // Button capture
  const onCameraReadyButton = useCallback((video: HTMLVideoElement, stream: MediaStreamTrack) => {
    setStream(stream);
    setVideo(video);
  }, []);

  const onTakePhoto = () => {
    if (!video) {
      return;
    }

    const selfie = takeSelfie(
      video,
      panelConfigurationData.scanConfiguration.imageExtensionFormat,
      panelConfigurationData.scanConfiguration.imageJPEGCompression,
    );
    setSelfiePreview(selfie);
  };

  const onSend = () => {
    if (!video || !stream) {
      return;
    }
    const img: HTMLImageElement = document.createElement("img");
    img.src = selfiePreview;
    checkFace(img, threshold)
      .then((resultList) => {
        if (resultList.length > 1) {
          setFeedbackSnackbar("More than one face detected", "warning");
          setFeedbackSnackbar(`${resultList.length} faces detected`, "info", true);
        } else if (resultList.length === 0) {
          setFeedbackSnackbar("No face detected", "warning", true);
        } else if (resultList.length === 1 && resultList[0].score < detectionSensibility) {
          setFeedbackSnackbar("Face detected but Face detector confidence score is too low", "warning", true);
        } else {
          const r: faceapi.FaceDetection = resultList[0];
          const box: faceapi.Box<any> = r.box;
          const imageDims = r.imageDims;

          const resultRescale = feedbackErrors(video, imageDims, box, configCropping, true);

          if (resultRescale) {
            faceDetected(selfiePreview, resultRescale, stream, video, r.score, true);
          }
        }
      })
      .catch((e) => console.warn(e));
  };

  return (
    <div className={classes.root}>
      <TinyFace>
        <div className={classes.parentContainer}>
          {selfiePreview === "" ? (
            <>
              <Webcam
                camera={props.camera}
                onInitStream={
                  panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.Scan
                    ? onCameraReadyScan
                    : onCameraReadyButton
                }
              />
              {panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.Button && (
                <div className={classes.takePhotoButtonParent}>
                  <Typography color="textSecondary" gutterBottom className={classes.iconLabel}>
                    Take a photo!
                  </Typography>
                  <IconButton onClick={onTakePhoto}>
                    <CameraAltRoundedIcon className={classes.cameraIcon} />
                  </IconButton>
                </div>
              )}
            </>
          ) : (
            <>
              <img src={selfiePreview} alt={"Selfie preview"} className={classes.loadingContainer} />
              <div className={classes.previewButtonsContainer}>
                <div className={classes.previewButtonParent}>
                  <Typography color="textSecondary" gutterBottom className={classes.iconLabel}>
                    Repeat!
                  </Typography>
                  <IconButton onClick={() => setSelfiePreview("")}>
                    <RepeatRoundedIcon className={classes.cameraIcon} />
                  </IconButton>
                </div>
                <div className={classNames(classes.previewButtonParent, classes.sendMessage)}>
                  <Typography color="textSecondary" gutterBottom className={classes.iconLabel}>
                    Send photo!
                  </Typography>
                  <IconButton onClick={onSend}>
                    <SendRoundedIcon className={classes.cameraIcon} />
                  </IconButton>
                </div>
              </div>
            </>
          )}
        </div>
      </TinyFace>
    </div>
  );
};
