import { CircularProgress, Divider, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ColumnStats, StatInfo, StatsMap } from "../../models/stats";
import React, { useCallback, useEffect, useState } from "react";
import { Row, RowData, StatTable, statColumns } from "./StatTable";

import { Conditional } from "../Utils/Conditional";
import Config from "../../config";
import { StatFilter } from "./StatFilter";
import { StatsService } from "../../api/stats";

const statsService = new StatsService(Config.apiUrl);

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: "inline-block",
    },
    progressIcon: {
      display: "inline-block",
      marginLeft: "10px",
    },
    titleContainer: {
      textAlign: "center",
      marginTop: "10px",
    },
  }),
);

/**
 * Calculate the percentages by row for each stat provided.
 *
 * @param stats The stats the server provides.
 * @param rows The row names the current stat will render in the table. It must
 * match with the keys in 'stats' provided.
 */
export const getPercentages = (stats: StatsMap, rows: string[]): Row[] => {
  // Calculate percentages for each row.
  return rows.map((title) => {
    // Sum all values in a row to get the total.
    const rowTotal = statColumns
      .filter((c) => c !== "total")
      .map((c) => stats[title][c])
      .reduce((a, b) => a + b, 0);

    // Calculate the percentage for all values. Loop all columns inside current
    // row to calculate the values => colum_value / total.
    const percentageData = {} as RowData; // This avoid to initialize each property in 'RowData'
    statColumns.forEach((c) => {
      percentageData[c] = rowTotal === 0 ? 0 : stats[title][c] / rowTotal;
    });

    return {
      title: title,
      percentage: true,
      data: percentageData,
    };
  });
};

/**
 * Takes the 'StatsMap' entity and transform it to a list of 'Row' entity that
 * 'StatTable' component is able to handle. It calculates the total and
 * percentage rows from incoming stats.
 *
 * @param stats The stats the server provides.
 * @param rows The row names the current stat will render in the table. It must
 * match with the keys in 'stats' provided.
 */
const getData = (stats: StatsMap, rows: string[]): Row[] => {
  // Parse 'stats' to 'Row'.
  const data: Row[] = rows.map((rowName) => {
    return {
      title: rowName,
      data: stats[rowName],
    };
  });

  // Calculate 'total' row.
  let totalCount = 0;
  const total: Row = { title: "total", data: {} as RowData };
  statColumns.forEach((c) => {
    const columnTotal = data.map((d) => d.data[c]).reduce((a, b) => a + b, 0);
    total.data[c] = columnTotal;
    if (c === "total") {
      totalCount = columnTotal;
    }
  });

  // Calculate percentages row.
  const percentages: Row = {
    title: "percentage",
    percentage: true,
    data: {} as RowData,
  };
  statColumns.forEach((c) => {
    percentages.data[c] = totalCount === 0 ? 0 : total.data[c] / totalCount;
  });

  // Build final result.
  data.push(total);
  data.push(percentages);
  return data;
};

interface Props {
  from: Date;
  to: Date;
  info: StatInfo;
  column: ColumnStats;
  rows: string[];
}

export const Stat: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Row[]>([]);
  const [percentages, setPercentages] = useState<Row[]>([]);
  const [filterValue, setFilterValue] = useState<string | null>(null);
  const [error, setError] = useState<Error>();

  const getStats = useCallback(() => {
    setLoading(true);
    setError(undefined);
    return statsService
      .getAll(props.column, props.from, props.to, props.info.filter, filterValue)
      .then((response) => {
        setPercentages(getPercentages(response, props.rows));
        setData(getData(response, props.rows));
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [props.column, props.from, props.to, props.info.filter, props.rows, filterValue, setError]);

  // Get data for current stat.
  useEffect(() => {
    getStats();
  }, [getStats]);

  return (
    <Conditional error={error} errorMessage="Failed to load stats">
      <>
        <Divider />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title} variant="h4" color="textPrimary" gutterBottom>
              {props.info.title}
            </Typography>
            {loading && <CircularProgress className={classes.progressIcon} color="primary" size={"24px"} />}
          </Grid>
          <StatFilter type={props.info.filter} value={filterValue} onChange={setFilterValue} onError={setError} />
          <StatTable rows={data} />
          <StatTable rows={percentages} />
        </Grid>
      </>
    </Conditional>
  );
};
