import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import { EndpointsConfiguration } from "../../models/config";
import React from "react";

const PREFERENCES_SECTION_DESCRIPTION = "Preferences for each endpoint";

enum EndpointsSection {
  Preferences = "Preferences",
}

export const EndpointsPanel: React.FC<ConfigurationProps<EndpointsConfiguration>> = (props) => {
  const { onConfigurationChange, isAdmin, config } = props;
  const section = config.endpointsConfiguration;

  const endpoints: Array<{ name: string; fieldName: keyof EndpointsConfiguration }> = [
    { name: "Age Scan", fieldName: "age" },
    { name: "Research", fieldName: "research" },
    { name: "Age AntiSpoofing", fieldName: "ageAntiSpoofing" },
    { name: "AntiSpoofing", fieldName: "antiSpoofing" },
    { name: "Selfcheckout Age AntiSpoofing", fieldName: "selfcheckoutAgeAntiSpoofing" },
  ];

  const changeConfiguration = (fieldName: keyof EndpointsConfiguration, checked: boolean) => {
    const configClone = JSON.parse(JSON.stringify(config.endpointsConfiguration)) as EndpointsConfiguration;
    configClone[fieldName].storeImages = checked;
    onConfigurationChange(configClone);
  };

  return (
    <div>
      <ConfigurationSection title={EndpointsSection.Preferences} description={PREFERENCES_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Endpoints configuration">
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Endpoint</TableCell>
                <TableCell>Store images</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {endpoints.map((e) => (
                <TableRow key={e.name}>
                  <TableCell>{e.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={section[e.fieldName].storeImages}
                      onChange={(_, checked) => changeConfiguration(e.fieldName, checked)}
                      disabled={!isAdmin}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ConfigurationSubsection>
      </ConfigurationSection>
    </div>
  );
};
