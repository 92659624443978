import * as faceapi from "face-api.js";

import { ImageExtensionFormat } from "../models/image";
import { poll } from "./polling";

/*
 * Tiny Face Detector: this model is used to detect faces on webcam. Documentation can be found here:
 * https://github.com/justadudewhohacks/face-api.js/#getting-started-face-detection-options
 */

export async function checkFace(
  media: HTMLVideoElement | HTMLImageElement,
  threshold: number,
): Promise<faceapi.FaceDetection[]> {
  const tinyFaceDetectorOptions = new faceapi.TinyFaceDetectorOptions({
    inputSize: 320,
    scoreThreshold: threshold,
  });
  const result: faceapi.FaceDetection[] = await faceapi.detectAllFaces(media, tinyFaceDetectorOptions);
  return result;
}

export function takeSelfie(video: HTMLVideoElement, format: ImageExtensionFormat, compression: number) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return "";
  }

  const cameraW = video.videoWidth;
  const cameraH = video.videoHeight;
  const isInPortrait = cameraW < cameraH;
  const low = isInPortrait ? cameraW : cameraH;
  const high = isInPortrait ? cameraH : cameraW;
  canvas.width = isInPortrait ? low : high;
  canvas.height = isInPortrait ? high : low;

  ctx.save();
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  return format === ImageExtensionFormat.PNG
    ? canvas.toDataURL("image/png")
    : canvas.toDataURL("image/jpeg", compression);
}

export function waitForFace<T>(
  media: HTMLVideoElement | HTMLImageElement,
  interval: number,
  threshold: number,
  endPollCondition: (faces: faceapi.FaceDetection[]) => T | undefined,
): Promise<T> {
  return poll<T>(async () => {
    const result = await checkFace(media, threshold);
    return endPollCondition(result);
  }, interval);
}
