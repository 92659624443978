function csvDownload<T>(data: T[], fileName?: string) {
  const items = data;
  const fileNameString = fileName || "export.csv";

  if (items.length > 0) {
    const header = Object.keys(items[0]) as (keyof T)[];

    const csv = items.map((row) =>
      header
        .map((fieldName) => {
          const field = row[fieldName];
          return JSON.stringify(
            field ? (typeof field === "string" && field === "0001-01-01T00:00:00Z" ? "" : field) : "",
          );
        })
        .join(","),
    );
    csv.unshift(header.join(","));

    const csvString = csv.join("\r\n");

    const blob = new Blob([csvString], {
      type: "text/plain;charset=utf-8",
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileNameString);
      return;
    }
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileNameString;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default csvDownload;
