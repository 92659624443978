import Axios, { AxiosRequestConfig } from "axios";

import { invalidSessionError } from "../models/account";
import { latestErrorMsg } from "../utils/error";

function removeCookies() {
  const res = document.cookie;
  const multiple = res.split(";");
  for (let i = 0; i < multiple.length; i++) {
    const key = multiple[i].split("=");
    document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  }
}

// removeLocalSessionData cleans the cache and local storage
export function removeLocalSessionData() {
  // Remove all cookies.
  removeCookies();
  // Remove all local storage.
  localStorage.clear();
}

export class ApiBase {
  protected url: string;
  public loginPath: string;

  constructor(url: string) {
    if (!url) {
      throw Error("URL cannot be empty");
    }
    if (!url.endsWith("/")) {
      url += "/";
    }
    this.url = url;
    this.loginPath = "/login";
  }

  protected async fetch<T>(url: string, init?: AxiosRequestConfig): Promise<T> {
    // Build url for current request. Use base url as root in case it
    // does not start with http or https.
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      if (url && url.startsWith("/")) {
        url = url.substr(1);
      }
      url = `${this.url}${url}`;
    }

    try {
      const res = await Axios(url, init);

      return res.data;
    } catch (e) {
      if (e.response?.status) {
        if (e.response?.data?.error_code === invalidSessionError) {
          removeLocalSessionData();
          window.location.replace(this.loginPath);
        }
        if (init && init.responseType === "arraybuffer") {
          const decoder = new TextDecoder();
          const message = decoder.decode(e.response.data);
          const errObj = JSON.parse(message);
          throw Error(errObj.error_message);
        }

        throw Error(`Request failed (${e.response.status}): ${latestErrorMsg(e.response.data.error_message)}`);
      }

      throw e;
    }
  }
}
